import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { graphql } from 'gatsby';

import { Headline, P } from './Typography';
import { colors } from '../helpers/variables';
import { fluidImageType } from '../helpers/prop-types';

const Wrapper = styled.div`
    position: relative;
`;

const Text = styled.div`
    background-color: ${colors.white};
    width: 80%;
    margin: -6% auto 0 auto;
    padding: 2em 2em 1em;
    position: relative;
    z-index: 2;

    &:after,
    &:before {
        position: absolute;
        left: 2em;
        content: '';
        height: 2px;
        width: ${rem(65)};
        background-color: ${colors.primary};
    }

    &:before {
        top: 1em;
    }

    &:after {
        bottom: 0;
    }
`;

const ReferenceSliderItem = ({ image, title, teaser }) => (
    <Wrapper>
        <Img fluid={image.childImageSharp.fluid} alt="Impression unserer letzten Projekte" />
        {title && teaser && (
            <Text>
                <Headline level="h3">{title}</Headline>
                <P>{teaser}</P>
            </Text>
        )}
    </Wrapper>
);

ReferenceSliderItem.propTypes = {
    image: fluidImageType.isRequired,
    title: PropTypes.string,
    teaser: PropTypes.string,
};

ReferenceSliderItem.defaultProps = {
    title: null,
    teaser: null,
};

/**
 * GraphQL Fragment für GatsbyImage
 * @type {GraphQL}
 */
// eslint-disable-next-line no-undef
export const query = graphql`
    fragment SliderImageFluid on File {
        childImageSharp {
            fluid(maxWidth: 1230, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
`;

export default ReferenceSliderItem;
