import React from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import HomeStage from '../components/HomeStage';
import Section from '../components/Section';
import TextImgBox from '../components/TextImgBox';
import ContactTeaser from '../components/ContactTeaser';
import Container from '../components/Container';
import { Headline, P } from '../components/Typography';
import FullwidthTeaser from '../components/FullwidthTeaser';
import Slider from '../components/Slider';
import ReferenceSliderItem from '../components/ReferenceSliderItem';
import Intro from '../components/Intro';
import Button from '../components/Button';
import NewsOrReferenceBox from '../components/NewsOrReferenceBox';
import { getImageFromNode } from '../helpers/utils';

/**
 * Die Komponente der Startseite
 * @param {Object} data Daten aus der GraphQL-Query
 */
const StartPage = ({ data }) => {
    const { textImgBoxImages, loxoneImage, aboutImage, homeStageImages } = data;
    const newsData = data.news.edges.filter(item => item.node.uid !== 'placeholder');
    const referenceData = data.references.edges.filter(item => item.node.uid !== 'placeholder');
    const StyledLink = styled.a`
        text-decoration: none;
    `;

    const newsBoxes = newsData.map(({ node: { data: news, id, uid } }) => {
        return (
            <StyledLink href={`/news?uid=${uid}`} key={id}>
                <NewsOrReferenceBox
                    image={news.teaserimage.localFile}
                    title={news.headline.text}
                    teaser={news.teaser.text}
                    boxType="news"
                />
            </StyledLink>
        );
    });

    const referenceItems = referenceData.map(({ node: { data: reference, id } }) => {
        return (
            <ReferenceSliderItem
                key={id}
                title={reference.headline.text}
                teaser={reference.teaser.text}
                image={reference.detailimages[0].detailimage.localFile}
            />
        );
    });

    return (
        <Layout title="Startseite" description="Startseite Gottwald Elektrotechnik">
            <HomeStage
                images={homeStageImages.edges[0].node.data.sliderimages}
                autoplay
                fillBg="gray100"
            />
            <Section bg="gray" container="l">
                <Headline level="h2" textAlign="center" gap="xxl">
                    Entdecken Sie Gottwald Elektrotechnik
                </Headline>
                <TextImgBox
                    headline="Gebäude Automation (Smart Home)"
                    gap="xxxl"
                    content="
                        Ein Loxone Smart Home ist ein intelligentes Haus, das die meisten Aufgaben rund um Sicherheit, Komfort und Energie-Effizienz von selbst erledigt. Gerne kümmern wir uns auch um ihr Projekt.
                    "
                    button={{
                        text: 'Mehr erfahren',
                        to: '/loxone',
                    }}
                    image={
                        getImageFromNode(textImgBoxImages, 'start/textimgbox-smarthome.jpg').node
                            .childImageSharp
                    }
                />
                <TextImgBox
                    headline="Renovierung oder Neubau?"
                    gap="xxl"
                    reverse
                    content="
                        In ihrer Vielseitigkeit bedarf es an individuellem Know How und breitgefächerter Kompetenz. Ob Elektrotechnik, Datentechnik oder Sicherheitstechnik – unser qualifiziertes Fachpersonal überzeugt mit hochwertigen Leistungen und ist gerne für Sie im Einsatz.
                    "
                    button={{
                        text: 'Mehr erfahren',
                        to: '/leistungen#elektrotechnik',
                    }}
                    image={
                        getImageFromNode(
                            textImgBoxImages,
                            'start/textimgbox-renovierung-neubau.jpg'
                        ).node.childImageSharp
                    }
                />
                <TextImgBox
                    headline="Photovoltaik. Mit Gottwald Elektrotechnik."
                    content="
                        Alles aus einer Hand : Wir bieten den kompletten Service von der Planung bis hin zur Montage und Inbetriebnahme. Mit unserer mehr als 15-jährigen Erfahrung im Bereich der Photovoltaik können wir auf ein breites Fachwissen im Gebiet der Regenerativen Energien zurückgreifen.
                    "
                    button={{ text: 'Mehr erfahren', to: '/' }}
                    image={
                        getImageFromNode(textImgBoxImages, 'start/textimgbox-photovoltaik.jpg').node
                            .childImageSharp
                    }
                />
            </Section>
            <ContactTeaser />
            {/* eslint-disable react/jsx-wrap-multilines */}
            <FullwidthTeaser reverse image={loxoneImage.childImageSharp} bg="white">
                <Intro
                    headline="Kennen Sie Loxone? <br /> Loxone ist der führende Hersteller im Bereich Smart
                    Home. <br /> Und wir sind Silber Partner."
                    headlineLevel="h3"
                    cta={<Button to="/loxone">Mehr über Loxone</Button>}
                >
                    <P>
                        Bei der Gebäudeautomation, auch Smart Home genannt, spielen alle Komponenten
                        zusammen und ersparen Ihnen tausende Handgriffe im Jahr. Egal ob
                        Beschattung, Heizung oder Licht &ndash; Loxone bringt alles perfekt in
                        Einklang.
                    </P>
                </Intro>
            </FullwidthTeaser>

            {/* eslint-enable react/jsx-wrap-multilines */}
            {newsBoxes && newsBoxes.length > 0 && (
                <Section bg="gray">
                    <Headline level="h2" textAlign="center" gap="xl">
                        Bei uns gibt es immer etwas Neues.
                    </Headline>
                    <Container size="l" grid cols="3">
                        {newsBoxes}
                    </Container>
                </Section>
            )}

            {referenceItems && referenceItems.length > 0 && (
                <Section bg={newsBoxes && newsBoxes.length > 0 ? 'white' : 'gray'}>
                    <Headline level="h2" textAlign="center" gap="l">
                        Eine Auswahl unserer Letzten Projekte
                    </Headline>
                    <Slider
                        gap="xxxl"
                        hideCounter
                        specialArrowStyles={css`
                            top: 0;
                            margin-top: 21.34145%;
                        `}
                    >
                        {referenceItems}
                    </Slider>
                    <Container size="s" display="flex" justify="center">
                        <Button to="/referenzen">Weitere Referenzen</Button>
                    </Container>
                </Section>
            )}

            <FullwidthTeaser image={aboutImage.childImageSharp}>
                <Intro
                    headline="Wir sind Gottwald Elektrotechnik"
                    cta={<Button to="/ueber-uns">Mehr über uns</Button>}
                >
                    <P>
                        Wir sind ihr kompetenter und professioneller Elektrofachbetrieb und stehen
                        unseren Kunden und Interessenten in Zimmerbach und Umgebung schon seit über
                        30 Jahren mit Rat und Tat zur Seite. Auf eine persönliche und individuelle
                        Betreuung unserer Kunden legen wir sehr großen Wert – so sorgt unser
                        Familienbetrieb dafür, dass sich die Wünsche unserer Kunden nach einer
                        zeitgemäßen Elektroinstallation erfüllen werden – mit Erfolg!
                    </P>
                </Intro>
            </FullwidthTeaser>
        </Layout>
    );
};

StartPage.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object.isRequired,
};

export default StartPage;

/**
 * Query für benötigte Assets der Seite
 * @type {GraphQL}
 */
export const query = graphql`
    {
        textImgBoxImages: allFile(
            filter: { relativePath: { glob: "start/textimgbox-*.jpg" } }
            sort: { fields: [relativePath], order: ASC }
        ) {
            edges {
                node {
                    relativePath
                    ...TextImgBoxFluid
                }
            }
        }
        loxoneImage: file(relativePath: { glob: "start/loxone.png" }) {
            childImageSharp {
                fluid(maxWidth: 650, quality: 80, toFormat: JPG) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        aboutImage: file(relativePath: { glob: "start/about.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1000, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        homeStageImages: allPrismicHomeslider {
            edges {
                node {
                    data {
                        sliderimages {
                            sliderimage {
                                localFile {
                                    ...SliderImageFluid
                                }
                            }
                        }
                    }
                }
            }
        }
        news: allPrismicNews(sort: { fields: first_publication_date }, limit: 4) {
            edges {
                node {
                    id
                    uid
                    data {
                        headline {
                            text
                        }
                        teaser {
                            text
                        }
                        teaserimage {
                            localFile {
                                ...NewsOrReferenceBoxFluid
                            }
                        }
                    }
                }
            }
        }
        references: allPrismicReference(sort: { fields: first_publication_date }, limit: 4) {
            edges {
                node {
                    id
                    uid
                    data {
                        headline {
                            text
                        }
                        teaser {
                            text
                        }
                        detailimages {
                            detailimage {
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 860, quality: 80) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
